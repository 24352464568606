import gql from 'graphql-tag'

export const FilterEditFragment = gql`
  fragment FilterEdit on Filter {
    collection {
      _id
      name
      fields {
        name
        label
        type
        fieldType {
          _id
          allowedOperatorsIds
        }
        options
        optional
      }
    }
  }
`

export const FilterUpdateBasicFragment = gql`
  fragment FilterUpdateBasic on Filter {
    name
    title
  }
`

export const FilterUpdateConditionsFragment = gql`
  fragment FilterUpdateConditions on Filter {
    conditions {
      rules {
        fieldName
        operatorId
        type
        optional
        fixed
        parameterName
        editableLabel
        operatorInputOptions
        sizeSmall
        sizeMedium
        sizeLarge
      }
    }
  }
`

export const FilterCreateFragment = gql`
  fragment FilterCreate on Filter {
    _id
    collectionId
    environmentId
    createdAt
  }
`

export const FilterFragment = gql`
  fragment Filter on Filter {
    ...FilterCreate
    ...FilterEdit
    ...FilterUpdateBasic
    ...FilterUpdateConditions
  }

  ${FilterEditFragment}
  ${FilterCreateFragment}
  ${FilterUpdateBasicFragment}
  ${FilterUpdateConditionsFragment}
`
